/* Custom antd CSS */
/* Dropdown */
/* Css for dropdown in main layout */
.ant-dropdown-user-info {
  min-width: auto !important;
}

/* Spin */
.antd-spin-wrapper {
  /* Fix space error in ui */
  font-size: unset;
}

/* Radio */
.ant-radio-checked .ant-radio-inner {
  background: var(--color-bg-linear) !important;
  border: none !important;
}

/* Checkbox */
.ant-checkbox-checked .ant-checkbox-inner {
  background: var(--color-bg-linear) !important;
  border: none !important;
}

.ant-checkbox-checked::after {
  border: none !important;
}

.ant-checkbox-inner {
  margin-top: 2px;
}

/* Modal confirm */
.modal-confirm-custom .ant-modal-content {
  padding: 32rem 32rem 24rem;
}

.modal-confirm-custom .ant-modal-content .ant-modal-confirm-title {
  font-weight: 700;
}

.modal-confirm-custom .ant-modal-content .ant-modal-confirm-content:empty {
  margin-top: 0 !important;
  margin-block-start: 0 !important;
}

.modal-confirm-custom .ant-modal-content .ant-modal-confirm-btns {
  margin-top: 24px;
}

/* Modal common */
.modal-common-custom .ant-modal-content {
  padding: 0;
}

.modal-common-custom .ant-modal-title {
  font-weight: 500;
}

.modal-common-custom .ant-modal-content .ant-modal-close {
  width: 16px;
  height: 16px;

  top: 20px;
  right: 24px;
}

.modal-common-custom .ant-modal-content .ant-modal-close > span {
  line-height: 16px;
}

.modal-common-custom .ant-modal-content .ant-modal-header {
  margin: 0;
  padding: 16px 24px;

  font-weight: 500;
  border-bottom: 1px solid #f0f0f0;
}

.modal-common-custom .ant-modal-content .ant-modal-body {
  padding: 24px;
}

.modal-common-custom .ant-modal-content .ant-modal-footer {
  margin: 0;
  padding: 16px 24px;
  border-top: 1px solid #f0f0f0;
}

/* Chart */
.g2-tooltip::after {
  content: "";
  width: 8px;
  height: 8px;

  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);

  border-radius: 50% 0 var(--border-radius) 0;
  background-color: var(--color-primary);
  opacity: 1 !important;
}

.g2-html-annotation{
  z-index: 1 !important;
}

/* Member statistic donut chart */
/* For file MemberStatisticDonutChart.tsx */
.member-statistic-content {
  width: 100%;
  color: #000000d9;
  white-space: normal;
  word-break: break-all;

  display: flex;
  justify-content: center;
  align-items: baseline;
}

.member-statistic-content.wrap-line {
  flex-direction: column;
  align-items: center;
}

.member-statistic-content .total-member {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  padding-inline: 8px;
}

.member-statistic-content .unit {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

/* modal */
.ant-modal-wrap.video-modal-wrapper {
  .ant-modal-footer {
    position: relative;

    .ant-btn {
      position: absolute;
      top: 40rem;
      left: 50%;
      transform: translate(-50%, -50%);
      background: transparent;
      border: 0;
      color: var(--color-white);
      font-size: 50px;
    }
  }
}

.modal-style {
  .btn-wrapper {
    justify-content: end !important;
  }
  .btn-action--1,
  .btn-action--2 {
    margin-top: 0 !important;
  }

  .btn-action--1 {
    background: #ffffff !important;
    border: 1px solid #d9d9d9 !important;
    color: var(--color-black) !important;
    &:active {
      color: var(--color-black) !important;
      border: 1px solid #d9d9d9 !important;
    }
  }
}

/* date picker */
.ant-picker-week-panel-row-selected td.ant-picker-cell-disabled .ant-picker-cell-inner{
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-picker-week-panel-row-selected td.ant-picker-cell-disabled:before{
    background-color: rgba(0, 0, 0, 0.04) !important;
}

.ant-picker-dropdown .ant-picker-time-panel-column:after{
  display: none !important;
}