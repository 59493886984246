/* Font config */
/* https://fonts.google.com/noto/specimen/Noto+Sans+JP */
@font-face {
  font-family: NotoSansJP;
  font-weight: 100;
  src: url(assets/fonts/NotoSansJP/NotoSansJP-Thin.ttf);
}

@font-face {
  font-family: NotoSansJP;
  font-weight: 200;
  src: url(assets/fonts/NotoSansJP/NotoSansJP-ExtraLight.ttf);
}

@font-face {
  font-family: NotoSansJP;
  font-weight: 300;
  src: url(assets/fonts/NotoSansJP/NotoSansJP-Light.ttf);
}

@font-face {
  font-family: NotoSansJP;
  font-weight: 400;
  src: url(assets/fonts/NotoSansJP/NotoSansJP-Regular.ttf);
}

@font-face {
  font-family: NotoSansJP;
  font-weight: 500;
  src: url(assets/fonts/NotoSansJP/NotoSansJP-Medium.ttf);
}

@font-face {
  font-family: NotoSansJP;
  font-weight: 600;
  src: url(assets/fonts/NotoSansJP/NotoSansJP-SemiBold.ttf);
}

@font-face {
  font-family: NotoSansJP;
  font-weight: 700;
  src: url(assets/fonts/NotoSansJP/NotoSansJP-Bold.ttf);
}

@font-face {
  font-family: NotoSansJP;
  font-weight: 800;
  src: url(assets/fonts/NotoSansJP/NotoSansJP-ExtraBold.ttf);
}

@font-face {
  font-family: NotoSansJP;
  font-weight: 900;
  src: url(assets/fonts/NotoSansJP/NotoSansJP-Black.ttf);
}

/* Common */
body {
  margin: 0;
  font-family: NotoSansJP, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
