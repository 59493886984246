/* Css variables */
:root {
  /* color */
  --color-primary: #ff6b00;
  --color-primary-fade: #ff6b00e5;
  --color-secondary: #ee1c28;
  --color-logo: #17479d;
  --color-background: #ffffff;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-black-2: #17181d;
  --color-gray: #e7e7e7;
  --color-gray-bold: #bebebe;
  --color-black-fade: #17181dcc;
  --color-border: #d9d9d9;
  --color-disabled: #d9d9d9;
  --color-selected: #ffe8d7;
  --color-error: #f5222d;
  --color-bg-linear: linear-gradient(269.37deg, #ff9a03 5.48%, #ff4d00 97.06%);
  --color-orange-light: #fff1e8;
  --color-success: #52c41a;
  --color-bg-success: #f6ffed;

  /* font size */
  --font-size-xs: 12px;
  --font-size-sm: 14px;
  --font-size-md: 16px;
  --font-size-lg: 24px;

  /* font weight */
  --font-weight-normal: 400;
  --font-weight-md: 500;
  --font-weight-bold: 600;
  --font-weight-bolder: 700;

  /* clip-path */
  --hexagon-clip-path: polygon(
    50% 0,
    100% 25%,
    100% 75%,
    50% 100%,
    0% 75%,
    0% 25%
  );

  /* border */
  --border-radius: 2px;
  --border-config: 1px solid #dddddd;

  /* Font */
  --font-family: NotoSansJP, Arial, Helvetica, Verdana, Tahoma, sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: NotoSansJP, Arial, Helvetica, Verdana, Tahoma, sans-serif;
}

body {
  /* Space between underline and text  */
  text-underline-offset: 2px;
  font-size: var(--font-size-md);
}

/* not scroll background */
.scroll-disabled {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

.custom-text-gradient {
  background: linear-gradient(91.78deg, #ffeccf 2.16%, #ffc165 103.68%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
